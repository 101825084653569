<template>
  <div class="row p-1 m-0 d-flex justify-content-center">
    <div class="col-md-4" v-if="version != null">
      <div class="card">
        <h5 class="card-header text-center">
          {{ version?.data?.appDescription }}
        </h5>
        <div class="card-body">
          <div class="text-center w-100">
            <i class="fab fa-codepen fa-3x mr-1" aria-hidden="true"></i>
          </div>
          <h5 class="card-title text-center">
            {{ version?.data?.appNombre }}
          </h5>
          <h4 class="text-center">V{{ version?.data?.appVersion }}</h4>
          <p class="card-text">
            TFM,si desea para mas informacion
            acerca de core contacte a los numeros<br />
          </p>
          <div class="d-flex justify-content-center">
            <a
              href="https://api.whatsapp.com/send?phone=593994276157&text=Hola necesito asesoría"
              class="btn btn-outline-primary m-1"
              target="_blank"
              ><i class="fab fa-whatsapp"></i>+593994276157
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=593960033032&text=Hola%20necesito%20asistencia%20t%C3%A9cnica"
              class="btn btn-outline-primary m-1"
              target="_blank"
              ><i class="fab fa-whatsapp"></i>+593960033032
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card">
        <h5 class="card-header text-center">API</h5>
        <div class="card-body">
          <div class="text-center w-100">
            <i class="fas fa-cloud-meatball fa-3x mr-1" aria-hidden="true"></i>
          </div>
          <h5 class="card-title text-center">Documentación</h5>
          <p class="card-text">
            La documentacion de los API's se encuentran documentadas usando Open
            Api v3 (Swagger)
          </p>
          <div class="d-flex justify-content-center">
            <a
              href="swagger-ui.html"
              target="_blank"
              class="btn btn-outline-primary"
              ><i class="fas fa-file-import"></i>Ver Documentación</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import { mapState } from "vuex";
export default {
  name: "Home",
  components: {
    // HelloWorld
  },
  computed: {
    ...mapState(["version"]),
  },
};
</script>
